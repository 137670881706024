.hidden {
    display: none;
}

.hidden--mobile {
    display: none;
}

.hidden--mobile-s {
    display: none;
}

@include bp-mobile-l() {

    .hidden--mobile-s {
        display: block;
    }

    .hidden--exept-mobile-s {
        display: none;
    }
}

@include bp-desktop-m() {
    .hidden--mobile {
        display: block;
    }
    .hidden--desktop {
        display: none;
    }
}
