.linkbox {
    border: $lineW-s solid black;
    padding: 0px 5px;
    display: inline-block;
    margin-top: 4px;
}

@include bp-mobile-l() {

}

@include bp-desktop-m() {
    .linkbox {
        padding: 0px 2px;
        line-height: $f-size-body-desktop-m - 1px;
        margin-top: 0px;
    }
    .linkbox--margin {
        margin-bottom: 10px;
    }

    .linkbox--marginTop {
        margin-top: 10px;
    }

}

@include bp-desktop-l() {
    .linkbox {
        line-height: $f-size-body-desktop-l - 1px;

    }
}

@include bp-desktop-xl() {
    .linkbox {
        line-height: $f-size-body-desktop-xl - 1px;
        border: $lineW-l solid black;
    }

}
