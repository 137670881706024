.mietenSub__img {
    width: 100%;
    margin-bottom: 18px;
    height: calc((100vw - 2 * #{$space-side-mobile-s}) * 0.51);
    background-position: center; 
    background-size: cover;
}

.dropdown__content__block {
    margin-bottom: 14px;
}

@include bp-mobile-l() {
    .mietenSub__img {
        height: calc((100vw - 2 * #{$space-side-mobile-l}) * 0.51);
    }
}

@include bp-desktop-m() {
    .mietenSub__img {
        margin-bottom: 28px;
        height: 329px;
    }

    .mieten__list li {
        margin-bottom: 17px;
    }

    .mieten-constrained-linkbox {
        width: 126px;
    }

}

@include bp-desktop-l() {
    .mietenSub__img {
        margin-bottom: 32px;
        height: 445px;
    }

    .mieten-constrained-linkbox {
        width: auto;
    }

}

@include bp-desktop-xl() {
    .mietenSub__img {
        height: 617px;
        margin-bottom: 48px;
    }
}
