.howToGetThere li:before {
    content: "- ";
    margin-left: -10px;
}

.howToGetThere li {
    margin-left: 10px;
}

.contact__block {
    margin-bottom: 30px;
}

.contact-linkbox-kontaktform {
    margin-top: 10px;
}

@include bp-mobile-l() {
    .page-kontakt .constrainedWidth {
        width: 60%;
    }
}

@include bp-desktop-m() {
    .constrained-width-kontakt-heading {
        max-width: 400px;
    }

    .contact-linkbox-kontaktform {
        margin-top: 20px;
    }
}
