/******************************
 *  carousel
 ******************************/
.carousel--full {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
}

.carousel--small {
    width: 100%;
    height: calc((100vw - 2 * #{$space-side-mobile-s}) * 0.51);
    overflow: hidden;
}

.carousel__gallery {
    margin: 0;
    padding: 0;
}

.carousel__gallery__li {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.carousel--small__gallery__li {
    height: calc((100vw - 2 * #{$space-side-mobile-s}) * 0.51);
}

.carousel__gallery__li.onscreen {
    opacity: 1;
}

.carousel__gallery__li__imgcontainer {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel__gallery__li__img {
    width: 100%;
    height: auto;
    position: absolute;
}

.carousel__gallery__li__img--centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carousel__gallery__li__img--alignedTop {
    top: 0;
    left: 0;
}

.carousel__gallery__li__gif {
    background-position: center center;
    height: 100vh;
    width: 100vw;
    position: fixed;
}

.carousel__gallery__li__gif--customTile {
    background-size: 500px;
}

.carousel__gallery__li__img--H {
    height: 100%;
    width: auto;
}

.carousel__gallery__li__img--mieten {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}


/******************************
 *  caption
 ******************************/
.carousel__gallery__li__caption {
    text-align: center;
    color: $c-text-gallery;
    width: 100%;
    text-shadow: 2px 2px 2px #222222;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel__gallery__li__caption__trigger {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 80px);
    height: 100px;
    z-index: 300;
    opacity: 0;
    transition: opacity .3s ease-in;
}

.carousel__gallery__li__caption__trigger--current {
    opacity: 1;
}

.carousel__gallery__li__caption--secondary {
    opacity: 0;
}

.carousel__gallery__li__caption--secondary--uppercase {
    text-transform: uppercase;
}

.notouchscreen {
    .carousel__gallery__li__caption__trigger:hover .carousel__gallery__li__caption--original {
        opacity: 0;
    }

    .carousel__gallery__li__caption__trigger:hover .carousel__gallery__li__caption--secondary {
        opacity: 1;
    }
}

/******************************
 *  arrows
 ******************************/
.carousel__arrows {
    position: relative;
    height: 100%;
}

.carousel__arrows__item__container {
    position: absolute;
    top: 0;
    z-index: 200;
    height: 100%;
    width: 40px;
}

.carousel__arrows__item__container--left {
    left: 0;
}

.carousel__arrows__item__container--right {
    right: 0;
}

.touchscreen {

    .carousel--full__arrows__item__container {
        position: fixed;
    }

    .carousel__arrows__item {
        position: absolute;
        width: 36px;
        height: 36px;
        border-top: 5px solid;
        top: 50%;
    }

    .carousel__arrows__item--left {
        border-left: 5px solid;
        left: $space-side-mobile-s;
        transform-origin: left top;
        transform: rotate(-45deg);
    }

    .carousel__arrows__item--right {
        border-right: 5px solid;
        right: $space-side-mobile-s;
        transform-origin: right top;
        transform: rotate(45deg);
    }

    .carousel__arrows__item--right--balck, .carousel__arrows__item--left--black {
        border-color: black;
    }

    .carousel__arrows__item--right--white, .carousel__arrows__item--left--white {
        border-color: $c-text-gallery;
    }

}

.notouchscreen {
    .carousel__arrows__item {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        padding: 0px;
    }

    .carousel__arrows__item--left {
        right: auto;
        left: 0;
        cursor: url(../images/arrowLeft_black_s.png) 0 26, auto;
    }

    .carousel__arrows__item--right {
        left: auto;
        right: 0;
        cursor: url(../images/arrowRight_black_s.png) 28 26, auto;
    }

    .carousel__arrows__item--left--black {
        cursor: url(../images/arrowLeft_black_s.png) 0 26, auto;
    }

    .carousel__arrows__item--right--black {
        cursor: url(../images/arrowRight_black_s.png) 28 26, auto;
    }

    .carousel__arrows__item--left--white {
        cursor: url(../images/arrowLeft_white_s.png) 0 26, auto;
    }

    .carousel__arrows__item--right--white {
        cursor: url(../images/arrowRight_white_s.png) 28 26, auto;
    }
}

.ie , .edge{
    .carousel__arrows__item--left {
        cursor: url(../images/arrowLeft_black_s.cur), auto;
    }

    .carousel__arrows__item--right {
        cursor: url(../images/arrowRight_black_s.cur), auto;
    }

    .carousel__arrows__item--left--black {
        cursor: url(../images/arrowLeft_black_s.cur), auto;
    }

    .carousel__arrows__item--right--black {
        cursor: url(../images/arrowRight_black_s.cur), auto;
    }

    .carousel__arrows__item--left--white {
        cursor: url(../images/arrowLeft_white_s.cur), auto;
    }

    .carousel__arrows__item--right--white {
        cursor: url(../images/arrowRight_white_s.cur), auto;
    }
}

/******************************
 *  close
 ******************************/
.carousel__closeGallery__wrap {
    z-index: 200;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: $space-side-mobile-s;
    box-sizing: content-box;
}


.carousel__closeGallery {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel__closeGallery__x {
    width: 141%;
    height: 5px;
    background: #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
}

.carousel__closeGallery__x--first {
    transform: translate(-50%, -50%) rotate(45deg);
}

.carousel__closeGallery__x--last {
    transform: translate(-50%, -50%) rotate(-45deg);
}

@include bp-mobile-l() {

    .carousel--small__gallery__li {
        height: calc((100vw - 2 * #{$space-side-mobile-l}) * 0.51);
    }

    .carousel--small {
        height: calc((100vw - 2 * #{$space-side-mobile-l}) * 0.51);
    }

    .carousel__arrows__item--left {
        padding-left: $space-side-mobile-l;

    }

    .carousel__arrows__item--right {
        padding-right: $space-side-mobile-l;
    }

    .carousel__closeGallery__wrap { padding: $space-side-mobile-l; }

}

@include bp-desktop-m() {

    .carousel--small {
        height: 329px;
    }

    .carousel--small__gallery__li {
        height: 329px;
    }

    .carousel__gallery__li__gif--full {
        background-repeat: no-repeat;
        background-size: cover;
    }

    .carousel__closeGallery__wrap { padding: $space-side-desktop-m; }

    .carousel__arrows__item__container {
        width: 50%;
    }

    .carousel__arrows__item--left {
        right: 400px;
        left: auto;
    }

    .carousel__arrows__item--right {
        left: 400px;
        right: auto;
    }

    .touchscreen {
        .carousel__arrows__item--left {
            left: 170px + $space-side-desktop-m;
            right: auto;
        }

        .carousel__arrows__item--right {
            right: 170px + $space-side-desktop-m;
            left: auto;
        }

        .carousel--small__arrows__item--left {
            left: $space-side-desktop-m;
        }

        .carousel--small__arrows__item--right {
            right: $space-side-desktop-m;
        }
    }

    .carousel--small__arrows__item--left {
        left: $space-side-desktop-m;
        right: auto;
    }

    .carousel--small__arrows__item--right {
        right: $space-side-desktop-m;
        left: auto;
    }

    .carousel__gallery__li__caption__trigger {
        width: 600px;
    }
}

@include bp-desktop-l() {

    .carousel--small {
        height: 445px;
    }

    .carousel--small__gallery__li {
        height: 445px;
    }

    .carousel__arrows__item--left {
        padding-left: $space-side-desktop-l;

    }

    .carousel__arrows__item--right {
        padding-right: $space-side-desktop-l;
    }

    .carousel__closeGallery__wrap { padding: $space-side-desktop-l; }

    .carousel__arrows__item--left {
        right: 400px;
    }

    .carousel__arrows__item--right {
        left: 400px;
    }

    .touchscreen {
        .carousel__arrows__item--left {
            left: 170px + $space-side-desktop-l;
            right: auto;
        }

        .carousel__arrows__item--right {
            right: 170px + $space-side-desktop-l;
            left: auto;
        }

        .carousel--small__arrows__item--left {
            left: $space-side-desktop-l;
        }

        .carousel--small__arrows__item--right {
            right: $space-side-desktop-l;
        }

    }

    .carousel--small__arrows__item--left {
        left: $space-side-desktop-l;
        right: auto;
    }

    .carousel--small__arrows__item--right {
        right: $space-side-desktop-l;
        left: auto;
    }
}

@include bp-desktop-xl() {

    .carousel--small {
        height: 617px;
    }

    .carousel--small__gallery__li {
        height: 617px;
    }

    .carousel__arrows__item--left {
        padding-left: $space-side-desktop-xl;

    }

    .carousel__arrows__item--right {
        padding-right: $space-side-desktop-xl;
    }

    .carousel__closeGallery__wrap { padding: $space-side-desktop-xl; }

    .carousel__arrows__item--left {
        right: 650px;
        left: auto;
    }

    .carousel__arrows__item--right {
        left: 650px;
        right: auto;
    }

    .touchscreen {
        .carousel__arrows__item--left {
            left: 300px + $space-side-desktop-xl;
            right: auto;
        }

        .carousel__arrows__item--right {
            right: 300px + $space-side-desktop-xl;
            left: auto;
        }

        .carousel--small__arrows__item--left {
            left: $space-side-desktop-xl;
        }

        .carousel--small__arrows__item--right {
            right: $space-side-desktop-xl;
        }

    }

    .carousel--small__arrows__item--left {
        left: $space-side-desktop-xl;
        right: auto;
    }

    .carousel--small__arrows__item--right {
        right: $space-side-desktop-xl;
        left: auto;
    }

}

