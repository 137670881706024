.logo {
    position: absolute;
}

.logo__container {
    min-height: calc(100vh - #{$space-side-mobile-s});
    position: relative;
}

.logo--top {
    right: 0;
    height: 1.7 * $f-size-headingXL-mobile-s;
}

.logo--bottom {
    right: 0;
    bottom: $space-side-mobile-s;
    height: .90 * $f-size-headingXL-mobile-s;
}

.logo--bottom__container {
    height: $f-size-headingXL-mobile-s + 42px;
}

@include bp-mobile-l() {

    .logo__container {
        min-height: calc(100vh - #{$space-side-mobile-l});
    }

    .logo--top {
        height: 1.7 * $f-size-headingXL-mobile-l;
    }

    .logo--bottom {
        height: .90 * $f-size-headingXL-mobile-l;
    }

    .logo--bottom__container {
        height: $f-size-headingXL-mobile-l + 42px;
    }

}

@include bp-desktop-m() {

    .logo__container {
        min-height: calc(100vh - #{$space-side-desktop-m});
    }

    .logo {
        position: fixed;
        right: $space-side-desktop-m;
    }

    .logo--top {
        top: $space-side-desktop-m;
        height: 1.7 * $f-size-headingXL-desktop-m;
    }

    .logo--bottom {
        bottom: $space-side-desktop-m;
        height: .90 * $f-size-headingXL-desktop-m;
    }

    .logo--bottom__container {
        height: $space-side-desktop-m;
    }
}

@include bp-desktop-l() {

    .logo__container {
        min-height: calc(100vh - #{$space-side-desktop-l});
    }

    .logo {
        right: $space-side-desktop-l;
    }

    .logo--top {
        top: $space-side-desktop-l;
        height: 1.7 * $f-size-headingXL-desktop-l;
    }

    .logo--bottom {
        bottom: $space-side-desktop-l;
        height: .90 * $f-size-headingXL-desktop-l;
    }

    .logo--bottom__container {
        height: $space-side-desktop-l;
    }
}

@include bp-desktop-xl() {

    .logo__container {
        min-height: calc(100vh - #{$space-side-desktop-xl});
    }

    .logo {
        right: $space-side-desktop-xl;
    }

    .logo--top {
        top: $space-side-desktop-xl;
        height: 1.7 * $f-size-headingXL-desktop-xl;
    }

    .logo--bottom {
        bottom: $space-side-desktop-xl;
        height: .90 * $f-size-headingXL-desktop-xl;
    }

    .logo--bottom__container {
        height: $space-side-desktop-xl;
    }

}

