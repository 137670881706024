.page-home .siteNav {
    position: fixed;
    top: 50%;
    right: $space-side-mobile-s;
    transform: translate(0, -50%);
    margin: 0;
    text-align: right;
}

.page-home .logo {
    display: none;
}

.homeLogo {
    position: fixed;
}

.homeLogo-top {
    top: $space-side-mobile-s;
}

.homeLogo-v {
    left: $space-side-mobile-s;
    height: .74 * $f-size-headingXL-desktop-m;
}

.homeLogo-kshaus {
    right: $space-side-mobile-s;
    height: 1.7 * $f-size-headingXL-desktop-m;
}

.homeLogo-zurich {
    bottom: $space-side-mobile-s;
    right: $space-side-mobile-s;
    height: .9 * $f-size-headingXL-desktop-m;
}

@include bp-mobile-l() {

    .homeLogo-top {
        top: $space-side-mobile-l;
    }

    .homeLogo-v {
        left: $space-side-mobile-l;
    }

    .homeLogo-kshaus {
        right: $space-side-mobile-l;
    }

    .homeLogo-zurich {
        bottom: $space-side-mobile-l;
        right: $space-side-mobile-l;
    }
    .page-home .siteNav {
        right: $space-side-mobile-l;
    }

}

@include bp-desktop-m() {
    .homeLogo-top {
        top: $space-side-desktop-m;
    }

    .homeLogo-v {
        left: $space-side-desktop-m;
    }

    .homeLogo-kshaus {
        right: $space-side-desktop-m;
    }

    .homeLogo-zurich {
        bottom: $space-side-desktop-m;
        right: $space-side-desktop-m;
    }

    .page-home .siteNav {
        right: $space-side-desktop-m;
    }

}

@include bp-desktop-l() {
    .homeLogo-top {
        top: $space-side-desktop-l;
    }

    .homeLogo-v {
        left: $space-side-desktop-l;
        height: .74 * $f-size-headingXL-desktop-l;
    }

    .homeLogo-kshaus {
        right: $space-side-desktop-l;
        height: 1.7 * $f-size-headingXL-desktop-l;
    }

    .homeLogo-zurich {
        bottom: $space-side-desktop-l;
        right: $space-side-desktop-l;
        height: .9 * $f-size-headingXL-desktop-l;
    }

    .page-home .siteNav {
        right: $space-side-desktop-l;
    }

}

@include bp-desktop-xl() {
    .homeLogo-top {
        top: $space-side-desktop-xl;
    }

    .homeLogo-v {
        left: $space-side-desktop-xl;
        height: .74 * $f-size-headingXL-desktop-xl;
    }

    .homeLogo-kshaus {
        right: $space-side-desktop-xl;
        height: 1.7 * $f-size-headingXL-desktop-xl;
    }

    .homeLogo-zurich {
        bottom: $space-side-desktop-xl;
        right: $space-side-desktop-xl;
        height: .9 * $f-size-headingXL-desktop-xl;
    }

    .page-home .siteNav {
        right: $space-side-desktop-xl;
    }

}

@media screen and (max-height: 400px) {
    .homeLogo-top {
        top: $space-side-mobile-s;
    }

    .homeLogo-v {
        left: $space-side-mobile-s;
        height: 37.34px;
    }

    .homeLogo-kshaus {
        right: $space-side-mobile-s;
        height: 74.68px;
    }

    .homeLogo-zurich {
        bottom: $space-side-mobile-s;
        right: $space-side-mobile-s;
        height: 37.34px;
    }

    .page-home .siteNav {
        top: 55%;
    }

}

