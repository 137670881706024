.table {
    margin: 8px 0px;
    width: 100%;
    max-width: 500px;
    text-align: right;
    font-family: $f-date;
}

.firstColumn {
    text-align: left;

}

td, th {

    padding-right: 16%;

}

@media only screen and (max-width: 500px) {
    td, th {

        padding-right: 10%;

    }
}

@include bp-desktop-m() {
    .table {
        margin: 25px 0px;
        width: 598px;
        max-width: 598px;

    }

    td, th {

        padding-right: 18%;

    }
}

@include bp-desktop-l() {

    td, th {

        padding-right: 16%;

    }
}

@include bp-desktop-xl() {

    .table {
        width: 900px;
        max-width: 900px;
    }

    td, th {

        padding-right: 16%;

    }
}
