.siteNav {
    margin-bottom: 50px;
    position: relative;
    top: -3px;
    width: 150px;
}

.siteNav__H {
    font-size: $f-size-nav-mobile-s;
    line-height: $f-size-nav-mobile-s;
    cursor: pointer;

}

.siteNav_level2_container {
    height: 0;
    overflow: hidden;
    transition: height .2s ease-in;
}

.expandNav + .siteNav_level2_container_events {
    height: 40px;

}

.expandNav + .siteNav_level2_container_stiftung {
    height: 80px;
}

.siteNav .underline:after {
    opacity: 0;
}

.siteNav_link.active .underline:after {
    opacity: 1;
}

.siteNav__option:hover .underline:after {
    opacity: 1;
}

.expandNav:hover .underline:after {
    opacity: 0;
}

@include bp-mobile-l() {

    .siteNav__H {
        font-size: $f-size-nav-mobile-l;
        line-height: $f-size-nav-mobile-l;
    }

}

@include bp-desktop-m() {

    .siteNav {
        position: fixed;
        top: 50%;
        right: $space-side-desktop-m;
        transform: translate(0, -50%);
        margin: 0;
        text-align: right;
        width: 170px;
    }

    .siteNav__H {
        font-size: $f-size-nav-desktop-m;
        line-height: $f-size-nav-desktop-m;
    }
}

@include bp-desktop-l() {
    .siteNav {
        right: $space-side-desktop-l;
        width: 170px;
    }

    .siteNav__H {
        font-size: $f-size-nav-desktop-l;
        line-height: $f-size-nav-desktop-l;
    }

    .expandNav + .siteNav_level2_container_events {
        height: 44px;

    }

    .expandNav + .siteNav_level2_container_stiftung {
        height: 88px;
    }

}

@include bp-desktop-xl() {
    .siteNav {
        right: $space-side-desktop-xl;
        width: 260px;
    }

    .siteNav__H {
        font-size: $f-size-nav-desktop-xl;
        line-height: $f-size-nav-desktop-xl;
    }

    .expandNav + .siteNav_level2_container_events {
        height: 70px;

    }

    .expandNav + .siteNav_level2_container_stiftung {
        height: 148px;
    }

}
