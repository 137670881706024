.input {
    width: 100%;
    background: transparent;
    border-radius: 0;
    letter-spacing: 0.58px;
}

.input--border {
    border: 2px solid black;
}

.input--text {
    height: $f-size-body-mobile-s * 2;
    line-height: $f-size-body-mobile-s * 2;
    padding: 0 10px;
}

.input--textarea {
    height: 122px;
}

.input--option {
    padding-left: 10px;
    line-height: 40px;
    cursor: pointer;
}

.input--option:hover {
    background: black;
    color: white;
}

.input--radio {
    display: none;
}

.input--radio__checkbox {
    border: 2px solid black;
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    cursor: pointer;
}

.input--radio:checked + .label--radio .input--radio__checkbox__x {
    opacity: 1;
}

.input--radio__checkbox__x {
    background: black;
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity .4s;
}

.input--radio__checkbox__x--left {
    transform-origin: center center;
    width: 2px;
    height: 16px;
    top: 50%;
    left: calc(50% - 1px);
    transform: translateY(-50%) rotate(45deg);
}

.input--radio__checkbox__x--right {
    transform-origin: center center;
    width: 2px;
    height: 16px;
    top: 50%;
    left: calc(50% - 1px);
    transform: translateY(-50%) rotate(-45deg);
}

.label--radio__text {
    line-height: 20px;
    position: relative;
    left: 30px;
    cursor: pointer;
}

.space--form {
    margin-bottom: 12px;
}

.fakeOption:last-child {
    margin-bottom: 0px;
}

.label--block {
    display: block;
    clear: both;
}

.kontaktform-checkbox-heading {
    margin-bottom: 12px;
}

.options--container {
    border: 2px solid black;
    position: absolute;
    top: 0;
    padding-top: 40px;
}

.dropdown--contactform {
    position: relative;
}

@include bp-desktop-m() {

    .input--text {
        height: $f-size-body-desktop-m * 2;
        line-height: $f-size-body-desktop-m * 2;
    }

    .page-kontaktform .heading--L__marginBottom {
        margin-bottom: 20px;
    }

}

@include bp-desktop-l() {
    .input--text {
        height: $f-size-body-desktop-l * 2;
        line-height: $f-size-body-desktop-l * 2;
    }

    .page-kontaktform .heading--L__marginBottom {
        margin-bottom: 22.5px;
    }
}

@include bp-desktop-xl() {
    .input--text {
        height: $f-size-body-desktop-xl * 2;
        line-height: $f-size-body-desktop-xl * 2;
    }

    .input--border {
        border: 3px solid black;
    }

    .input--radio__checkbox {
        border: 3px solid black;
        height: 30px;
        width: 30px;
    }

    .input--radio__checkbox__x--left {
        width: 3px;
        height: 24px;
        left: calc(50% - 1.5px);
    }

    .input--radio__checkbox__x--right {
        width: 3px;
        height: 24px;
        left: calc(50% - 1.5px);
    }

    .label--radio__text {
        line-height: 30px;
        left: 40px;
    }

    .page-kontaktform .heading--L__marginBottom {
        margin-bottom: 34px;
    }

    .options--container {
        border: 3px solid black;
    }

}
