* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    //height: 100%;
}

body {
    font-family: $f-text;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

/******************************
 *  resets
 ******************************/
button:focus {
    outline: none;
}

button {
    background: inherit;
    border: none;
}
