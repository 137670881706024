.only-desktopm-m {
    display: none;
}

.personen-block {
    margin-bottom: 36px;
}

@include bp-mobile-only() {

    .page-personen .constrainedWidth {
        width: 330px;
    }
}

@include bp-desktop-m() {
    .hidden-desktopm-m {
        display: none;
    }

    .only-desktopm-m {
        display: inline;
    }
}

@include bp-desktop-l() {
    .hidden-desktopm-m {
        display: inline;
    }

    .only-desktopm-m {
        display: none;
    }
}

@include bp-desktop-xl() {
    .page-personen .constrainedWidth {
        width: 1350px;
    }
}
