.mieten__block {
    display: inline-block;
    width: calc(50vw - #{$space-side-mobile-s} - 4px);
    vertical-align: top;
}

.mieten__block--right {
	position:relative;
	left: 4px;
}

.mieten__block__img {
    width: 100%;
	background-repeat: no-repeat;
	height: calc(0.51 * (50vw - #{$space-side-mobile-s} - 4px));
	background-position: center; 
	background-size: cover;
}

.mieten__caption {
    position: relative;
    top: 1px;
    margin-bottom: 17px;
}

.grid-container {
    width: calc(100vw - 2 * #{$space-side-mobile-s});
    margin-left: $space-side-mobile-s;
    margin-top: $space-side-mobile-s;
}

.wordSeparation__theatersaal, .wordSeparation__xzimmer {
    display: none;
}

@include bp-mobile-l() {
    .grid-container {
        width: calc(100vw - 2 * #{$space-side-mobile-l});
        margin-left: $space-side-mobile-l;
        margin-top: $space-side-mobile-l;
    }

    .mieten__block{
		width: calc(50vw - #{$space-side-mobile-l} - 4px);
	}

	.mieten__block__img{
		height: calc(0.51 * (50vw - #{$space-side-mobile-l} - 4px));
	}
}

@include bp-desktop-m() {
    .mieten__block {
        margin-bottom: 28.8px;
        width: calc(0.5 * #{$main-width-desktop-m} - 16px);
    }

    .mieten__block__img{
		height: calc(0.51 * (0.5 * #{$main-width-desktop-m} - 16px));
	}

    .mieten__block--right {
        left: 25px;
    }

    .mieten__caption {
        position: relative;
        top: 9px;
        margin-bottom: 15px;
    }

    .mieten__caption__title {
        width: 80%;
    }

    .mieten__block__subText {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        line-height: 90%;
        padding-top: 4px;
    }
    .grid-container {
        width: calc(100vw - 2 * #{$space-side-desktop-m});
        margin-left: $space-side-desktop-m;
        margin-top: $space-side-desktop-m;
    }
}

@include bp-desktop-l() {
    .mieten__block {
    	width: calc(0.5 * #{$main-width-desktop-l} - 16px);
        margin-bottom: 32px;
    }

    .mieten__block__img{
		height: calc(0.51 * (0.5 * #{$main-width-desktop-l} - 16px));
    }

    .grid-container {
        width: calc(100vw - 2 * #{$space-side-desktop-l});
        margin-left: $space-side-desktop-l;
        margin-top: $space-side-desktop-l;
    }

    .mieten__block__subText {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        line-height: 96%;
        padding-top: 2px;
    }
    .mieten__caption {
        top: 10px;
        margin-bottom: 16.5px;
    }
}

@include bp-desktop-xl() {
    .mieten__block {
        width: calc(0.5 * #{$main-width-desktop-xl} - 16px);
        margin-bottom: 48px;
    }

    .mieten__block__img{
		height: calc(0.51 * (0.5 * #{$main-width-desktop-xl} - 16px));
	}

    .grid-container {
        width: calc(100vw - 2 * #{$space-side-desktop-xl});
        margin-left: $space-side-desktop-xl;
        margin-top: $space-side-desktop-xl;
    }

    .mieten__block__subText {
        width: 85px;
        top: 2px;
    }

    .mieten__caption {
        top: 15px;
        margin-bottom: 25px;
    }

    .wordSeparation__theater-saal {
        display: none;
    }

    .wordSeparation__theatersaal {
        display: block;
    }

}

@media only screen and (min-width: 441px) and (max-width: 959px) {
    .wordSeparation__theater-saal {
        display: none;
    }

    .wordSeparation__theatersaal {
        display: block;
    }
}

@media only screen and (min-width: 546px) and (max-width: 959px) {
    .wordSeparation__x-zimmer {
        display: none;
    }

    .wordSeparation__xzimmer {
        display: block;
    }
}
