.dropdown.expand .dropdown__content {
    display: block;
}

.dropdown.expand .dropdown__content--form {
    display: block;
    position: absolute;
    background: white;
    width: 100%;
    z-index: 1;
}

.dropdown.expand .dropdown__arrow {
    transform: rotate(180deg);
}

.dropdown__content--form {
    display: none;
}

.dropdown__content {
    display: none;
}

.dropdown__header {
    display: inline-block;
    cursor: pointer;
}

.dropdown__firstHeading {
    margin-top: 0;
}

.dropdown--mobile {
    margin-bottom: 36px;
}

@include bp-mobile-l() {

}

@include bp-desktop-m() {

    .dropdown--mobile {
        .dropdown__header {
            cursor: auto;
        }
        .dropdown__content {
            display: block;
        }
        .dropdown__arrow {
            display: none;
        }

        margin-bottom: 0px;
    }

    .dropdown.expand .dropdown__content--form {
        width: calc(#{$main-width-desktop-m} / 2 - 17px);
    }
}

@include bp-desktop-l() {
    .dropdown.expand .dropdown__content--form {
        width: calc(#{$main-width-desktop-l} / 2 - 17px);
    }

}

@include bp-desktop-xl() {
    .dropdown.expand .dropdown__content--form {
        width: calc(#{$main-width-desktop-xl} / 2 - 17px);
    }

}
