.underline {
    position: relative;
    display: inline-block;
}


.underline:after {
    content: '';
    display: inline-block;
    background-color: #000;
    width: 100%;
    height: $lineW-s;
    position: absolute;
    bottom: 2%;
    left: 0;

    @include bp-desktop-xl() { height: $lineW-l; }
}


.underline--main:after {
    height: 3px;
    bottom: -9%;

    @include bp-desktop-m() { height: 5px; }
    @include bp-desktop-xl() { height: 8px; }
}


.gallery .underline:after {
    background-color: $c-text-gallery;
}

