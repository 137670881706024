.line {
    display: block;
}

.line--mobile {
    display: block;
}

@include bp-desktop-m() {
    .line--mobile {
        display: inline-block;
    }

}

@include bp-desktop-l() {

}

@include bp-desktop-xl() {

}
