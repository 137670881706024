@include bp-mobile-l() {

}

@include bp-desktop-m() {
    .column--desktop--three {
        width: calc(33.3% - 22px);
        float: left;
        margin-right: $margin-column-desktop-m;
    }
    .column--desktop--two {
        width: calc(50% - 17px);
        float: left;
        margin-right: $margin-column-desktop-m;
    }

    .column--desktop--two--rightMargin {
        margin-right: 50vw;
        clear: both;
    }

    .column--desktop--last {
        margin-right: 0px;
    }

    .column--desktop--contact__margincorrection {
        margin-top: -150px;
    }

    .column--desktop--contact__margincorrection--anfahrt {
        margin-top: -150px;
    }

}

@include bp-desktop-l() {
    .column--desktop--three {
        width: calc(33.3% - 22px);
        float: left;
        margin-right: $margin-column-desktop-l;
    }
    .column--desktop--two {
        width: calc(50% - 17px);
        float: left;
        margin-right: $margin-column-desktop-l;
    }

    .column--desktop--two--rightMargin {
        margin-right: 50vw;
        clear: both;
    }

    .column--desktop--last {
        margin-right: 0px;
    }

    .column--desktop--contact__margincorrection {
        margin-top: -150px;
    }
    .column--desktop--contact__margincorrection--anfahrt {
        margin-top: -150px;
    }

}

@include bp-desktop-xl() {

}
