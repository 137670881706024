.page-entstehung {

    .heading--L__marginTop {
        margin-top: 27px;
    }

    .heading--L__marginBottom {
        margin-bottom: 12px;
    }

    .heading--L__marginTop:first-child {
        margin-top: 0;
    }

    .heading--M {
        margin-top: 8px;
        margin-bottom: 0px;
    }

}

@include bp-mobile-l() {
    .page-entstehung .constrainedWidth {
        width: 60%;
    }
}

@include bp-desktop-m() {
    .page-entstehung {

        .heading--L__marginTop {
            margin-top: 42px;
        }

        .heading--L__marginBottom {
            margin-bottom: 19px;
        }

        .heading--M {
            margin-top: 25px;
        }

        .constrainedWidth {
            width: 598px;
        }

    }

}

@include bp-desktop-l() {
    .page-entstehung {

        .heading--L__marginTop {
            margin-top: 48px;
        }

        .heading--L__marginBottom {
            margin-bottom: 21px;
        }

        .heading--M {
            margin-top: 25px;
        }

    }
}

@include bp-desktop-xl() {
    .page-entstehung {
        .heading--L__marginTop {
            margin-top: 72px;
        }

        .heading--L__marginBottom {
            margin-bottom: 32px;
        }
        .constrainedWidth {
            width: 900px;
        }

    }

}

