.map {
    width: 100%;
    height: 329px;
}

@include bp-desktop-m() {
    .map {
        margin-bottom: 11px;
    }

}

@include bp-desktop-l() {
    .map {
        height: 445px;
        margin-bottom: 15px;
    }

}

@include bp-desktop-xl() {
    .map {
        height: 617px;
        margin-bottom: 18px;
    }

}
