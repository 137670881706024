@font-face {
    font-family: 'gt-haptik';
    src: url('../fonts/GT-Haptik-Medium.eot?') format('eot'),
    url('../fonts/GT-Haptik-Medium.woff') format('woff'),
    url('../fonts/GT-Haptik-Medium.woff2') format('woff2'),
    url('../fonts/GT-Haptik-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt-haptik-tab';
    src: url('../fonts/GT-Haptik-Medium-Tab.eot?') format('eot'),
    url('../fonts/GT-Haptik-Medium-Tab.woff') format('woff'),
    url('../fonts/GT-Haptik-Medium-Tab.woff2') format('woff2'),
    url('../fonts/GT-Haptik-Medium-Tab.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
