// Define every font setting here and include mixin where needed. This way we can combine font families, sizes and weights
// in one single setting.

@mixin font-title-1() {
    font-family: $f-title;
    font-size: $f-size-title-1;
}

@mixin font-body-1() {
    font-family: $f-body;
    font-size: $f-size-body-1;
}

.date {
    font-family: $f-date;
}

a, a:visited {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

.heading--L {
    font-size: $f-size-headingL-mobile-s;
    line-height: $f-lineHeight-headingL-mobile-s;
    letter-spacing: $f-spacing-headingL-mobile-s;
    display: block;
    font-weight: normal;
}

.heading--L--uppercase {
    text-transform: uppercase;
}

.heading--L__marginBottom {
    margin-bottom: 13px;
}

.heading--L__marginTop {
    margin-top: 17px;
}

.heading--M {
    font-size: $f-size-headingM-mobile-s;
    line-height: $f-lineHeight-headingM-mobile-s;
    letter-spacing: $f-spacing-headingM-mobile-s;
    margin-bottom: $f-spaceAfter-headingM-mobile-s;
    text-transform: uppercase;
    display: block;
    font-weight: normal;
    margin-top: 10px;
}

.heading--S {
    font-size: $f-size-headingS-mobile-s;
    line-height: $f-lineHeight-headingS-mobile-s;
    letter-spacing: $f-spacing-headingS-mobile-s;
    display: block;
    font-weight: normal;
    margin-top: 10px;
}

body {
    font-size: $f-size-body-mobile-s;
    line-height: $f-lineH-body-mobile-s;
}

@include bp-mobile-l() {

    .heading--L {
        font-size: $f-size-headingL-mobile-l;
        line-height: $f-lineHeight-headingL-mobile-l;
        letter-spacing: $f-spacing-headingL-mobile-l;
    }

    .heading--M {
        font-size: $f-size-headingM-mobile-l;
        line-height: $f-lineHeight-headingM-mobile-l;
        letter-spacing: $f-spacing-headingM-mobile-l;
        margin-bottom: $f-spaceAfter-headingM-mobile-l;
        margin-top: 10px;
    }

    .heading--S {
        font-size: $f-size-headingS-mobile-l;
        line-height: $f-lineHeight-headingS-mobile-l;
        letter-spacing: $f-spacing-headingS-mobile-l;
        margin-top: 10px;
    }

    body {
        font-size: $f-size-body-mobile-l;
        line-height: $f-lineH-body-mobile-l;
    }

}

@include bp-desktop-m() {
    .heading--L {
        font-size: $f-size-headingL-desktop-m;
        line-height: $f-lineHeight-headingL-desktop-m;
        letter-spacing: $f-spacing-headingL-desktop-m;
    }

    .heading--M {
        font-size: $f-size-headingM-desktop-m;
        line-height: $f-lineHeight-headingM-desktop-m;
        letter-spacing: $f-spacing-headingM-desktop-m;
        margin-bottom: $f-spaceAfter-headingM-desktop-m;
        margin-top: 10px;
    }

    .heading--S {
        font-size: $f-size-headingS-desktop-m;
        line-height: $f-lineHeight-headingS-desktop-m;
        letter-spacing: $f-spacing-headingS-desktop-m;
        margin-top: 10px;
    }

    body {
        font-size: $f-size-body-desktop-m;
        line-height: $f-lineH-body-desktop-m;
    }

}

@include bp-desktop-l() {
    .heading--L {
        font-size: $f-size-headingL-desktop-l;
        line-height: $f-lineHeight-headingL-desktop-l;
        letter-spacing: $f-spacing-headingL-desktop-l;
    }

    .heading--M {
        font-size: $f-size-headingM-desktop-l;
        line-height: $f-lineHeight-headingM-desktop-l;
        letter-spacing: $f-spacing-headingM-desktop-l;
        margin-bottom: $f-spaceAfter-headingM-desktop-l;
        margin-top: 10px;
    }

    .heading--S {
        font-size: $f-size-headingS-desktop-l;
        line-height: $f-lineHeight-headingS-desktop-l;
        letter-spacing: $f-spacing-headingS-desktop-l;
        margin-top: 10px;
    }

    body {
        font-size: $f-size-body-desktop-l;
        line-height: $f-lineH-body-desktop-l;
    }

}

@include bp-desktop-xl() {
    .heading--L {
        font-size: $f-size-headingL-desktop-xl;
        line-height: $f-lineHeight-headingL-desktop-xl;
        letter-spacing: $f-spacing-headingL-desktop-xl;
    }

    .heading--M {
        font-size: $f-size-headingM-desktop-xl;
        line-height: $f-lineHeight-headingM-desktop-xl;
        letter-spacing: $f-spacing-headingM-desktop-xl;
        margin-bottom: $f-spaceAfter-headingM-desktop-xl;
        margin-top: 10px;
    }

    .heading--S {
        font-size: $f-size-headingS-desktop-xl;
        line-height: $f-lineHeight-headingS-desktop-xl;
        letter-spacing: $f-spacing-headingS-desktop-xl;
        margin-top: 10px;
    }

    body {
        font-size: $f-size-body-desktop-xl;
        line-height: $f-lineH-body-desktop-xl;
    }

    .heading--L__marginBottom {
        margin-bottom: 30px;
    }

    .heading--L__marginTop {
        margin-top: 30px;
    }

}
