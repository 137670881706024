.gallery .siteNav {
    z-index: 1;
    color: white;
}

.gallery .logo {
    display: none;
}

.gallery {
    background: black;
}

@include bp-mobile-only() {
    .gallery .siteNav {
        position: fixed;
        top: $space-side-mobile-s;
        left: $space-side-mobile-s;
    }
}
