.inputError {
    border: 2px solid red !important;
    color: red;
}

.labelError {
    color: red;
}

.errorMessage {
    color: red;
}
