.fl-left {
    float: left;
}

.fl-right {
    float: right;
}

.ta-l {
    text-align: left;
}

.ta-c {
    text-align: center;
}

.ta-r {
    text-align: right;
}

.display-inline-block {
    display: inline-block;
}

.constrainedWidth {
    width: $main-width-mobile-s;
    position: relative;
}

@include bp-mobile-l() {
    .constrainedWidth {
        width: $main-width-mobile-l;
    }
}

@include bp-desktop-m() {
    .constrainedWidth {
        width: $main-width-desktop-m;
    }
}

@include bp-desktop-l() {
    .constrainedWidth {
        width: $main-width-desktop-l;
    }
}

@include bp-desktop-xl() {
    .constrainedWidth {
        width: $main-width-desktop-xl;
    }
}
