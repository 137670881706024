.close {
    position: fixed;
    width: 21px;
    height: 21px;
    z-index: 2;
}

.close__x {
    width: 28px;
    height: 3px;
    display: inline-block;
    position: absolute;
    top: 42%;
    right: -5px;
}

.close__x--black {
    background: black;
}

.close__x--white {
    background: white;
}

.close__x--first {
    transform: rotate(45deg);
}

.close__x--last {
    transform: rotate(-45deg);
}

@include bp-mobile-l() {

}

@include bp-desktop-m() {
    .close--mieten {
        position: absolute;
        left: $main-width-desktop-m + 20px;
    }

    .close--kontaktform {
        position: absolute;
        right: 0;
    }

    .close {
        width: 33px;
        height: 33px;
    }

    .close__x {
        width: 40px;
        height: 5px;
        top: 42%;
        right: -5px;
    }
}

@include bp-desktop-l() {
    .close--mieten {
        position: absolute;
        left: $main-width-desktop-l + 20px;
    }

}

@include bp-desktop-xl() {
    .close--mieten {
        position: absolute;
        left: $main-width-desktop-xl + 20px;
    }
}

