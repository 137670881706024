.event {
    position: relative;
    margin-bottom: 6px;
    padding: 6px 0px 10px 10px
}

.event_month {
    font-size: $f-size-headingXL-mobile-s;
    line-height: $f-lineHeight-headingXL-mobile-s;
    margin-top: $marginT-size-headingXL-mobile-s;
    margin-bottom: $marginB-size-headingXL-mobile-s;
    font-weight: normal;
    text-transform: uppercase;
}

.event_month:first-child {
    margin-top: 0px;
}

.event_side_right {
    padding-left: 55px;
}

.event_side_left {
    position: absolute;
    top: 6px;
    left: 0;
    width: 34px;
}

.event_type {
    text-transform: uppercase;
}

.event_location {
    text-transform: capitalize;
}

/******************************
 *  img
 ******************************/

.event_img {
    opacity: 0;
    position: fixed;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@include bp-desktop-m() {
    .notouchscreen {

        .event_img {
            z-index: -4;
            top: 50%;
            left: 50%;
            width: 29vw;
            transform: translate(-50%, -50%);
        }
    }
}

.notouchscreen {
    .event__title:hover + .event_img {
        opacity: 1;
    }
}

.event_img {
    width: 46vw;
    bottom: $space-side-mobile-s;
    right: $space-side-mobile-s;
    z-index: 2;
}

.event.top .event_img {
    opacity: 1;
}

.event.top {
    border: 3px solid black;
}

.event {
    border: 3px solid transparent;
}

.space {
    height: 75vh;
}

.event {
    left: -8px;
    width: calc(100% + 16px);
}

.event_side_left {
    left: 8px;
}

/******************************
 *  ticket list
 ******************************/

.ticketList {
    display: none;
    background: white;
    position: absolute;
    top: -8px;
    left: 0px;
    width: 200px;
    padding: 7px 15px 12px 15px;
}

.ticketList p, .ticketList li {
    line-height: $f-size-body-mobile-s * 7 / 5;
}

.revealTicketList {
    display: inline-block;
    position: relative;
}

.revealTicketList--desktop:hover .ticketList, .revealTicketListMobile.reveal + .ticketList {
    display: block;
    z-index: 2;
}

@include bp-mobile-l() {

    .page-aktuell .constrainedWidth {
        width: 60%;
    }

    .event_img--mobile {
        bottom: $space-side-mobile-l;
        right: $space-side-mobile-l;
    }

    .event_side_right {
        padding-left: 50px;
    }

    .event_side_left {
        width: 44px;
    }

    .event_month {
        font-size: $f-size-headingXL-mobile-l;
        line-height: $f-lineHeight-headingXL-mobile-l;
        margin-top: $marginT-size-headingXL-mobile-l;
        margin-bottom: $marginB-size-headingXL-mobile-l;
    }

}

@include bp-desktop-m() {
    .touchscreen .event_img {
        height: calc(50vh - 140px);
        width: auto;
        bottom: $space-side-desktop-m;
        right: $space-side-desktop-m;
    }
    .page-aktuell .constrainedWidth {
        min-width: 600px;
    }
    .event {
        margin-bottom: $marginB-size-headingXL-desktop-m - 20px;;
    }

    .event__title {
        padding-bottom: 2px;
    }

    .event_side_right {
        padding-left: 107px;
    }

    .event_side_left {
        width: 60px;
    }

    .event_month {
        font-size: $f-size-headingXL-desktop-m;
        line-height: $f-lineHeight-headingXL-desktop-m;
        margin-top: $marginT-size-headingXL-desktop-m;
        margin-bottom: $marginB-size-headingXL-desktop-m;
    }

    .inbetweenSpace {
        margin-right: 8px;
    }

    .ticketList p, .ticketList li {
        line-height: $f-size-body-desktop-m * 7 / 5;
    }

    .ticketList {
        top: -10px;
    }

}

@include bp-desktop-l() {
    .event {
        margin-bottom: $marginB-size-headingXL-desktop-l - 20px;;
    }

    .event_side_left {
        width: 60px;
    }
    .event_side_right {
        padding-left: 107px;
    }

    .event_month {
        font-size: $f-size-headingXL-desktop-l;
        line-height: $f-lineHeight-headingXL-desktop-l;
        margin-top: $marginT-size-headingXL-desktop-l;
        margin-bottom: $marginB-size-headingXL-desktop-l;
    }

    .ticketList p, .ticketList li {
        line-height: $f-size-body-desktop-l * 7 / 5;
    }

}

@include bp-desktop-xl() {

    .event {
        margin-bottom: $marginB-size-headingXL-desktop-xl - 20px;
    }

    .event_side_left {
        width: 110px;
    }
    .event_side_right {
        padding-left: 162px;
    }

    .event_month {
        font-size: $f-size-headingXL-desktop-xl;
        line-height: $f-lineHeight-headingXL-desktop-xl;
        margin-top: $marginT-size-headingXL-desktop-xl;
        margin-bottom: $marginB-size-headingXL-desktop-xl;
    }

    .line-margincorrection {
        margin-bottom: 0px;
    }

    .ticketList p, .ticketList li {
        line-height: $f-size-body-desktop-xl * 7 / 5;
    }

    .ticketList {
        top: -14px;
    }

}
