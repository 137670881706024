@include bp-mobile-l() {

}

@include bp-desktop-m() {

    .lineheightException--body {
        line-height: $f-size-body-desktop-m;
    }

}

@include bp-desktop-l() {
    .lineheightException--body {
        line-height: $f-size-body-desktop-l;
    }

}

@include bp-desktop-xl() {
    .lineheightException--body {
        line-height: inherit;
    }

}
