/******************************
 *
 *  Margins and Paddings
 *
 ******************************/

$space-side-mobile-s: 8px;
$space-side-mobile-l: 8px;
$space-side-desktop-m: 24px;
$space-side-desktop-l: 24px;
$space-side-desktop-xl: 30px;

$margin-column-mobile-s: 24px;
$margin-column-mobile-l: 24px;
$margin-column-desktop-m: 33px;
$margin-column-desktop-l: 33px;
$margin-column-desktop-xl: 33px;

/******************************
 *
 *  Layout
 *
 ******************************/

$main-width-mobile-s: 100%;
$main-width-mobile-l: 100%;
$main-width-desktop-m: 640px;
$main-width-desktop-l: 866px;
$main-width-desktop-xl: 1300px;

/******************************
 *
 *  Breakpoints
 *
 ******************************/
@mixin bp-mobile-only() {
    @media only screen and (max-width: 960px) {
        @content;
    }
}

//tablets
@mixin bp-mobile-l() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

//All screens, bigger than tablets
@mixin bp-desktop-m() {
    @media only screen and (min-width: 960px) {
        @content;
    }
}

//desktop screens
@mixin bp-desktop-l() {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

// Only big desktop screens
@mixin bp-desktop-xl() {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}

/******************************
 *
 *	Typo
 *
 ******************************/

$f-logo: 'gt-haptik', Helvetica, sans-serif;
$f-title: 'gt-haptik', Helvetica, Arial, sans-serif;
$f-text: 'gt-haptik', Helvetica, Arial, sans-serif;
$f-date: 'gt-haptik-tab', Helvetica, Arial, sans-serif;

/******************************
 *  HEADING XL
******************************/
$f-size-headingXL-mobile-s: 57.5px;
$f-lineHeight-headingXL-mobile-s: 57.5px;

$f-size-headingXL-mobile-l: 57.5px;
$f-lineHeight-headingXL-mobile-l: 57.5px;

$f-size-headingXL-desktop-m: 81px;
$f-lineHeight-headingXL-desktop-m: 60px;

$f-size-headingXL-desktop-l: 81px;
$f-lineHeight-headingXL-desktop-l: 60px;

$f-size-headingXL-desktop-xl: 124px;
$f-lineHeight-headingXL-desktop-xl: 92px;

$marginT-size-headingXL-mobile-s: 43px;
$marginT-size-headingXL-mobile-l: 43px;
$marginT-size-headingXL-desktop-m: 67px;
$marginT-size-headingXL-desktop-l: 67px;
$marginT-size-headingXL-desktop-xl: 67px;

$marginB-size-headingXL-mobile-s: $f-size-headingXL-mobile-s / 2;
$marginB-size-headingXL-mobile-l: $f-size-headingXL-mobile-l / 2;
$marginB-size-headingXL-desktop-m: $f-size-headingXL-desktop-m / 2;
$marginB-size-headingXL-desktop-l: $f-size-headingXL-desktop-l / 2;
$marginB-size-headingXL-desktop-xl: $f-size-headingXL-desktop-xl / 2;

/******************************
*  HEADING L
******************************/
$f-size-headingL-mobile-s: 26px;
$f-spacing-headingL-mobile-s: .65px;
$f-lineHeight-headingL-mobile-s: 25px;

$f-size-headingL-mobile-l: 26px;
$f-spacing-headingL-mobile-l: .65px;
$f-lineHeight-headingL-mobile-l: 25px;

$f-size-headingL-desktop-m: 40px;
$f-spacing-headingL-desktop-m: .65px;
$f-lineHeight-headingL-desktop-m: 36.5px;

$f-size-headingL-desktop-l: 45px;
$f-spacing-headingL-desktop-l: .65px;
$f-lineHeight-headingL-desktop-l: 41.5px;

$f-size-headingL-desktop-xl: 68px;
$f-spacing-headingL-desktop-xl: .65px;
$f-lineHeight-headingL-desktop-xl: 63px;

/******************************
*  HEADING M
******************************/
$f-size-headingM-mobile-s: 16px;
$f-spacing-headingM-mobile-s: .55px;
$f-lineHeight-headingM-mobile-s: 20px;

$f-size-headingM-mobile-l: 16px;
$f-spacing-headingM-mobile-l: .55px;
$f-lineHeight-headingM-mobile-l: 20px;

$f-size-headingM-desktop-m: 18px;
$f-spacing-headingM-desktop-m: .55px;
$f-lineHeight-headingM-desktop-m: 20px;

$f-size-headingM-desktop-l: 20px;
$f-spacing-headingM-desktop-l: .55px;
$f-lineHeight-headingM-desktop-l: 22px;

$f-size-headingM-desktop-xl: 30px;
$f-spacing-headingM-desktop-xl: .55px;
$f-lineHeight-headingM-desktop-xl: 35px;

/******************************
*  HEADING S
******************************/
$f-size-headingS-mobile-s: 16px;
$f-spacing-headingS-mobile-s: .58px;
$f-lineHeight-headingS-mobile-s: 20px;

$f-size-headingS-mobile-l: 16px;
$f-spacing-headingS-mobile-l: .58px;
$f-lineHeight-headingS-mobile-l: 20px;

$f-size-headingS-desktop-m: $f-size-headingM-desktop-m;
$f-spacing-headingS-desktop-m: .58px;
$f-lineHeight-headingS-desktop-m: $f-lineHeight-headingM-desktop-m;

$f-size-headingS-desktop-l: 20px;
$f-spacing-headingS-desktop-l: .58px;
$f-lineHeight-headingS-desktop-l: 22px;

$f-size-headingS-desktop-xl: 30px;
$f-spacing-headingS-desktop-xl: .58px;
$f-lineHeight-headingS-desktop-xl: 35px;

/******************************
 *  BODY
 ******************************/
$f-size-body-mobile-s: 16px;
$f-lineH-body-mobile-s: 20px;

$f-size-body-mobile-l: 16px;
$f-lineH-body-mobile-l: 20px;

$f-size-body-desktop-m: $f-size-headingM-desktop-m;
$f-lineH-body-desktop-m: $f-lineHeight-headingM-desktop-m;

$f-size-body-desktop-l: 20px;
$f-lineH-body-desktop-l: 22px;

$f-size-body-desktop-xl: 30px;
$f-lineH-body-desktop-xl: 35px;

$f-spaceAfter-headingL-mobile-s: 17px;
$f-spaceAfter-headingM-mobile-s: 14px;
$f-spaceAfter-headingS-mobile-s: 0;

$f-spaceAfter-headingL-mobile-l: 17px;
$f-spaceAfter-headingM-mobile-l: 14px;
$f-spaceAfter-headingS-mobile-l: 0;

$f-spaceAfter-headingL-desktop-m: 17px;
$f-spaceAfter-headingM-desktop-m: 19px;
$f-spaceAfter-headingS-desktop-m: 0;

$f-spaceAfter-headingL-desktop-l: 17px;
$f-spaceAfter-headingM-desktop-l: 19px;
$f-spaceAfter-headingS-desktop-l: 0;

$f-spaceAfter-headingL-desktop-xl: 17px;
$f-spaceAfter-headingM-desktop-xl: 19px;
$f-spaceAfter-headingS-desktop-xl: 0;

$f-size-nav-mobile-s: $f-size-headingL-mobile-s;
$f-size-nav-mobile-l: $f-size-headingL-mobile-l;
$f-size-nav-desktop-m: $f-size-headingL-desktop-m;
$f-size-nav-desktop-l: $f-size-headingL-desktop-l;
$f-size-nav-desktop-xl: $f-size-headingL-desktop-xl;

$f-size-logo-mobile-s: $f-size-headingXL-mobile-s;
$f-size-logo-mobile-l: $f-size-headingXL-mobile-l;
$f-size-logo-desktop-m: $f-size-headingXL-desktop-m;
$f-size-logo-desktop-l: $f-size-headingXL-desktop-l;
$f-size-logo-desktop-xl: $f-size-headingXL-desktop-xl;

$f-size-nav-s-home: 35px;

$f-size-logo-s-home: 60px;

/******************************
 *
 *	Colors
 *
 ******************************/

$c-text: #000;
$c-text-gallery: #fff;

/******************************
 *
 *  linkbox and underline widths
 *
 ******************************/

$lineW-s: 2px;
$lineW-l: 3px;

